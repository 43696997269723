<!--  -->
<template>
  <div id="app">
    <div class="lll"></div>
    <van-row>
      <van-col span="8" class="login_bg">
        <div class="login_content">
          <img src="@/assets/images/distributionLogin/图标.png" alt="" />
          <p>通信网服中心</p>
        </div>
      </van-col>
      <van-col span="16" class="login">
        <div class="box">
          <div v-if="!codes">
            <img
              class="login_img"
              src="@/assets/images/distributionLogin/图标.png"
              alt=""
            />
            <ul class="login_way">
              <li :class="type == 1 ? 'active' : ''" @click="changeType(1)">
                验证码登录
              </li>
              <div></div>
              <li :class="type == 2 ? 'active' : ''" @click="changeType(2)">
                扫码登录
              </li>
            </ul>
            <div class="tab_con">
              <div class="tab_input" v-if="type == 1">
                <div class="login_input">
                  <div>+86</div>
                  <input
                    v-model="phone.val"
                    type="text"
                    placeholder="请输入您的手机号码"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    maxlength="11"
                    @blur="test()"
                  />
                  <p v-show="hintShow">{{ hint }}</p>
                </div>
                <div class="login_btn">
                  <button
                    @click="getSmsCode()"
                    :disabled="btnabled"
                    :class="{ btn1: classStatus }"
                  >
                    下一步
                  </button>
                </div>
              </div>
              <div class="Qrcode" v-if="type == 2"
               v-loading="loading1"
                element-loading-text="正在登陆中，请稍等..."
              >
                <div class="Qrcode_box"
                  v-loading="loading"
                    element-loading-text="拼命加载中"
                >
                  <img
                    :class="darkMode ? 'Qrcode_pic Qrcode_pic_active' : 'Qrcode_pic'"
                    :src="qrcodeImg"
                    alt=""
                  />
                  <div class="Qrcode_lose" v-show="showQrcode">
                       <!-- <i class="el-icon-warning"></i> -->
                       <img src="@/assets/images/distributionLogin/refresh.png" alt="">
                    <p>
                      二维码已过期，请<span @click="getlunxunData"
                        >重新获取</span
                      >
                    </p>
                  </div>
                </div>
                <p>使用通信网服务中心App扫码登录</p>
              </div>
            </div>
          </div>
          <div class="next" v-if="codes">
            <img
              class="login_img"
              src="@/assets/images/distributionLogin/图标.png"
              alt=""
            />
            <p>请输入验证码</p>
            <p>
              已发送至<span>{{ phone.val }}</span>
            </p>
            <div class="code">
              <input
                class="codeInput"
                v-model="item.value"
                type="number"
                maxlength="1"
                v-for="(item, index) in codeData"
                :key="index"
                @keyup="codeChange(index, item.value)"
                @keyup.delete="codeDeleteChange(index, item.value)"
                @focus="codeIndex == index"
              />
            </div>
            <p style="font-size: 16px" v-show="!show">
              <span style="color: #1e6fff">{{ count }}</span
              >秒后重新获取
            </p>
            <p
              style="
                font-size: 16px;
                cursor: pointer;
                color: #1e6fff;
                text-decoration: underline;
              "
              v-show="show"
              @click="getSmsCode"
            >
              重新获取
            </p>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import distubritionTwoApi from "@/untils/distubritionTwo.js";

export default {
  data() {
    return {
      darkMode:false,//动态添加类名
      qrcodeTimeout: "", //延时任务
      showQrcode: false, //二维码遮罩层
      show: true,
      count: "",
      loading: true,//加载
      loading1: false,//加载
      timer: null,
      code: "", //登录验证码
      disabled: false, // 禁用按钮
      type: 1,
      value: "",
      codes: false,
      classStatus: false,
      btnabled: true, //如果输入手机号不正确，按钮禁止
      hintShow: false, // 提示语显示
      hint: "手机号码错误", // 提示语
      phone: {
        val: "", //登录手机号
        err: false,
        pass: false,
      },
      qrcodeImg: "",
      codeIndex: 0,
      codeData: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
      token: "",
    };
  },
  watch: {
    phone: {
      handler(newValue, oldValue) {
        if (newValue.val) {
          this.btnabled = false;
          this.classStatus = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.login();//登录
    // this.getSmsCode();//发送验证码
    // this.getQrcode();
  },
  methods: {
    //获取二维码
    getQrcode() {
      let _this = this;
      distubritionTwoApi.quecode().then((res) => {
        console.log(res, "二维码");
        if (res.data.code == 200) {
          this.qrcodeImg = res.data.data.path;
          this.token = res.data.data.token;
          this.loading = false;

          _this.qrcodeDie();
        }
      });
    },
    //设置验证码过期时间
    qrcodeDie() {
      let _this = this;
      _this.qrcodeTimeout = setTimeout(function () {
        _this.showQrcode = true;
        _this.darkMode = true
        clearInterval(this.timer);
      }, 90*1000 );
    },
    //清除验证码延时任务
    clearQrcodeTime() {
      var _this = this;
      clearTimeout(_this.qrcodeTimeout);
    },

    // 二维码是否过期
    getLoginInfo() {
      var _this = this;
      let data = {
        token: this.token,
      };
      distubritionTwoApi.getLoginInfo({ data }).then((res) => {
        if (res.data.code != 0) {
          this.showQrcode = true;
          this.darkMode = true
          //清除验证码延时任务
          this.clearQrcodeTime();
          clearInterval(this.timer);
          this.loading1 = true;
          setTimeout(() => {
            // window.location.href = "http://localhost:8080/oderUpload";
            this.$router.push("/oderUpload");
          }, 1500);
          //用户信息存入缓存
        sessionStorage.setItem(
          "userInfo",
          JSON.stringify(res.data.data.data)
        );
      console.log(res.data.data.data,'用户信息');
        if (sessionStorage.getItem("userInfo") == false) {
          this.$toast("缓存读取失败");
          return false;
        }
          console.log("登陆成功");
        }

        console.log(res, "二维码状态");
      });
    },
   
    // 倒计时
    getCodes() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    codeDeleteChange(index, value) {
      let codeIndex = --index > 0 ? index : 0;

      if (value == "") {
        let codeInputList = document.getElementsByClassName("codeInput");
        codeInputList[codeIndex].focus();
      }
    },
    //验证value
    codeValue(value) {
      if (value != undefined) {
        let valueArr = value.split("");
        return valueArr[valueArr.length - 1];
      }

      // return value.substr(str.length - 1, 1)
    },
    codeChange(index, value) {
      if (value == "") {
        return false;
      }
      if (value != undefined) {
        value = this.codeValue(value);
        this.codeData[index].value = value;
        let codeIndex = ++index;
        if (codeIndex == 6) {
          this.postCode(codeIndex, value);
        }
        let codeInputList = document.getElementsByClassName("codeInput");

        for (let index = 0; index < codeInputList.length; index++) {
          if (index == codeIndex) {
            codeInputList[index].focus();
            // console.log( codeInputList[index],'index');
          }
        }

        console.log(codeInputList, "codeInputList");
      }

      // this.codeIndex = ++index;
      // console.log(index,'index');
    },
    //验证登录
    postCode(index, value) {
      let codeData = this.codeData;
      let codeSter = "";
      for (let index = 0; index < codeData.length; index++) {
        codeSter += codeData[index].value;
      }
      this.code = codeSter;
      // console.log(codeSter, "codeSter");
      this.login();
      // console.log(this.codeData.toString(),'codeData');
      // let data =
      // if (index == 6) {
      //   let codeInputList = document.getElementsByClassName("codeInput");
      //   codeInputList[5].value = value;
      // }
    },

    // 手机号码验证
    test: function () {
      const reg =
        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
      if (
        this.phone.val == "" ||
        this.phone.val.length <= 10 ||
        !reg.test(this.phone.val)
      ) {
        this.hintShow = true;
        this.classStatus = false;
        this.btnabled = true;
        this.hint = "手机号码错误";
        this.err = true;
        return false;
      } else {
        this.classStatus = true;
        this.hintShow = false;
        this.btnabled = false;
        this.hint = "手机号码正确";
        this.err = false;
        return true;
      }
    },
    changeType(item) {
      this.type = item;
      if (item == 2) {
        window.clearInterval(this.timer);
        if (this.qrcodeImg == "") {
          this.getQrcode();
        }
        this.getlunxunData();
      }

      if (item == 1) {
        clearInterval(this.timer);
      }
    },
    //轮询
    getlunxunData() {
      this.showQrcode = false
      this.darkMode = false
      if (this.showQrcode == true) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.getLoginInfo();
      }, 2000);

      // this.showQrcode = false;
      // this.timer = setInterval(() => {
      //   this.getLoginInfo();
      //   setTimeout(() => {
      //     this.getTimer();
      //     this.showQrcode = true;
      //   }, 90 * 1000);
      // }, 1000);
    },
    getTimer() {
      window.clearInterval(this.timer);
    },
    //发送验证码  点击下一步

    getSmsCode() {
      this.codeChange(-1);
      let data = {
        mobile: this.phone.val,
      };
      this.getCode({ data }).then((res) => {
        if (res.data.code == 200) {
          this.codes = true;
          this.getCodes();
          this.show = false
          let ret = res.data;
          this.$toast(ret.msg);
        } else {
          this.$toast(res.data.msg);
        }
      });
    },

    login() {
      let _this = this;
      let data = {
        mobile: _this.phone.val,
        code: _this.code,
      };
      _this.disabled = true;
      //正则匹配手机号
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(data.mobile)) {
        this.$toast("手机号格式错误");
        return false;
      }
      //验证码格式验证
      if (data.code.length !== 6) {
        this.$toast("验证码格式错误");
        return false;
      }

      //发送登录请求
      this.mobileLogin({ data }).then((res) => {
        let ret = res.data;
        if (ret.code !== 200) {
          this.$toast(ret.msg);
          return false;
        } else {
          this.$toast(ret.msg);
        }
        //用户信息存入缓存
        sessionStorage.setItem(
          "userInfo",
          JSON.stringify(ret.data.data.userInfo)
        );

        if (sessionStorage.getItem("userInfo") == false) {
          this.$toast("缓存读取失败");
          return false;
        }
        this.$router.push("/oderUpload");
        //跳转xxx页面
      });

      setTimeout(() => {
        _this.disabled = false;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.Qrcode_box {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  position: relative;
}
.login_way {
  cursor: pointer;
}
.active {
  color: #1e6fff !important;
  font-size: 24px;
  font-weight: 700 !important;
}
.Qrcode_lose {
  width: 200px;
  height: 200px;
  /* line-height: 200px; */
  /* background-color: rgba(233, 223, 223, 0.8); */
  position: absolute;
  top: 0;
  left: 0;
}
.Qrcode_lose img{
  margin-top: 60px;
}
.Qrcode .Qrcode_lose p {
  font-weight: 550;
  color: #000;
}
.Qrcode_lose p span {
  text-decoration: underline;
  cursor: pointer;
  color: green !important;

}
.Qrcode_lose p span:hover {
  color: red;
}


html,
body,
#app {
  height: 100vh !important;
}
.login_bg {
  height: 100vh;
  background: url("~@/assets/images/distributionLogin/背景.png") no-repeat;
  background-size: 100% 100%;
}
.login_content {
  padding-top: 90px;
  padding-left: 86px;
  width: 73px;
  text-align: center;
}
.login_content img {
  width: 111px;
}
.login_content p {
  width: 120px;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-top: 15px;
}
.login {
  text-align: center;
  float: left;
  background-color: #fff;
  /* height: 1089px; */
  height: 100vh;
}
/* tab */
.current {
  font-size: 16px;
  color: #1e6fff;
  font-weight: 550;
}
.login .login_way {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  margin-bottom: 60px;
}
.login .login_img {
  margin-top: 182px;
}
.login .login_way li:nth-child(1) {
  font-size: 24px;
  line-height: 28px;
  font-weight: 290;
  color: #999;
}
.login .login_way div {
  width: 2px;
  height: 28px;
  background-color: #333;
  margin: 0 30px;
}
.login .login_way li:nth-child(3) {
  font-size: 24px;
  color: #999;
  line-height: 28px;
  font-weight: 290;
}
.login .login_input {
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: relative;
}
.login .login_input p {
  position: absolute;
  color: red;
  top: 20px;
  left: 300px;
}
.login .login_input div {
  height: 40px;
  width: 60px;
  border: 1px solid #ccc;
  font-size: 14px;
  line-height: 40px;
}
.login .login_input input {
  width: 300px;
  padding-left: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
}
.login_btn button {
  width: 371px;
  height: 48px;
  background-color: #9fceff;
  border-radius: 407px;
  margin-top: 40px;
  color: #fff;
  font-size: 14px;
}
.login_btn .btn1 {
  background-color: #1e6fff;
}
.Qrcode .Qrcode_pic {
  width: 200px;
  height: 200px;
}
.Qrcode_pic_active{
  opacity: 0.1;
}
.Qrcode p {
  color: #999;
  font-size: 14px;
  margin-top: 24px;
}
.next {
  text-align: center;
  margin: 0 auto;
}
.next p:nth-child(2) {
  font-size: 28px;
  color: #1e6fff;
  margin-top: 41px;
}
.next p:nth-child(3) {
  color: #3d3d3d;
  line-height: 28px;
  font-size: 12px;
  margin-top: 13px;
}

.next .code {
  /* margin-top: 30px; */
  margin: 40px;
  display: flex;
  justify-content: center;
}
.next .code input {
  width: 47px;
  height: 47px;
  margin: 0 10px;
  border: 1px solid #666;
  border-radius: 8px;
  font-size: 18px;
  text-align: center;
}
.next .van-password-input {
  width: 300px;
  text-align: center;
}
.next .van-password-input li {
  width: 47px;
  height: 47px;
  border: 1px solid #666;
  border-radius: 8px;
}
.box {
  width: 400px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}
.code input::-webkit-outer-spin-button,
.code input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>